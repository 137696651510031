/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import './modernizr-custom.js';

// ResponsiveBP
import '../../node_modules/responsive-bp/src/js/responsive.core.js';
import '../../node_modules/responsive-bp/src/js/responsive.navigation.js';

// Add custom hasChildren selector
$.expr[':'].hasChildren = function (element, index, selector) {
	return $(element).children().length >= (selector[3] || 1);
};

$(async function () {
	var wrapSwiper = function (element, childrenSelector = null, pagination = false, navigation = false) {
		var $element = $(element);
		$element.children(childrenSelector).addClass('swiper-slide');
		$element.wrapInner('<div class="swiper-wrapper" />');

		if (pagination) {
			$element.append('<div class="swiper-pagination" />');
		}

		if (navigation) {
			$element.append('<a class="swiper-button-prev"><i class="icon icon-chevron-left"></i></a>');
			$element.append('<a class="swiper-button-next"><i class="icon icon-chevron-right"></i></a>');
		}
	};

	document.querySelectorAll('.slider').forEach(function (elem) {
		(async () => {
			wrapSwiper(elem, null, false, false);
			const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
			var mySwiper = new Swiper(elem, {
				keyboard: true,
				loop: true,
				effect: 'slide',
				speed: 750,
				autoplay: {
					delay: 3000
				},
				slidesPerView: 6,
				spaceBetween: 40,
				// Responsive breakpoints
				breakpoints: {
					1024: {
						slidesPerView: 5
					},
					768: {
						slidesPerView: 4
					},
					640: {
						slidesPerView: 3
					},
					480: {
						slidesPerView: 2
					}
				}
				//on: {
				//	init: function () {
				//		// Refresh AOS, because activating Swiper changes layout
				//		AOS.refresh();
				//	}
				//}
			});
		})();
	});

	document.querySelectorAll('.slideshow').forEach(function (elem) {
		(async () => {
			wrapSwiper(elem, null, false, false);
			const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
			var mySwiper2 = new Swiper(elem, {
				keyboard: true,
				loop: true,
				effect: 'fade',
				speed: 750,
				autoplay: {
					delay: 3000
				}
			});
		})();
	});

	// Object fit fallback
	if (!Modernizr.objectfit) {
		$('.object-fit picture').each(function () {
			var element = $(this);
			var image = element.find('img');
			var parent = element.parent();

			parent.css({ 'background-size': 'cover', 'background-image': 'url(' + image.attr('src') + ')', 'background-position': 'center right' });
			element.remove();
		});
	}

	// Dropdown
	var navigation = $('nav');
	if (navigation.length) {
		$('nav li ul').addClass('collapse');
		await import("../../node_modules/responsive-bp/src/js/responsive.core.js");
		await import("../../node_modules/responsive-bp/src/js/responsive.dropdown.js");
	}

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});

	// Table wrap
	$('table').wrap("<div class='table-wrapper'></div>");

	// Lightbox
	$('main:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Change target for external sites
	var getDomainName = function (hostName) {
		return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
	};
	$('a:not([target])').filter(function () {
		var link = $(this);

		if (link.filter('[rel~="external"]').length) return true; // Link rel attribute contains 'external'

		var href = link.attr('href');
		if (new RegExp('\.pdf$', 'i').test(href)) return true; // Link to PDF file

		if (new RegExp('^https?://', 'i').test(href)) {
			var linkHostName = getDomainName(href.substring(href.indexOf('/') + 2, href.indexOf('/', 8)));
			if (!new RegExp('(^|\.)' + getDomainName(document.location.hostname) + '$', 'i').test(linkHostName)) {
				// Link is to different domain name
				return true;
			}
		}

		return false;
	}).attr('target', '_blank');
});